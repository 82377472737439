import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import "./index.scss"
//import Sparkles from "../components/Sparkles"

const IndexPage = () => (
  <>
    <Seo />
    <div className="fullscreen-bg d-flex flex-column justify-content-start justify-content-md-end">


      <StaticImage className="" src="../images/bg-gradient.jpg" alt="Background Image" />

      <div className="p-anchor cta-anchor">
        <div className="cta-box d-flex flex-column justify-content-between align-items-center">
          <p>
            A DROP BY <br/> 
            <a href="https://www.shrug.vc" target="_blank" rel="noreferrer">SHRUG</a>
            &nbsp;X&nbsp; 
            <a href="https://partyround.com" target="_blank" rel="noreferrer">PARTY ROUND</a>
          </p>
          <hr/>
          <h1>VC PUZZLE</h1>
          <h4>$0.99</h4>
          <hr/>
          <h6>69 VC FIRMS. ONE PUZZLE.</h6>
          <hr/>
          <div className="cta-actions">
            <a href="#" className="btn soldout">SOLD OUT</a>
          </div>
          <pre>¯\_(ツ)_/¯</pre>
        </div>
      </div>
      
      <div className="p-anchor">
        
            <StaticImage 
              className="p-element hero-box spin" 
              width={705} 
              placeholder={'BLURRED'}
              src="../images/main-hero-pixel.png" 
              alt="Background Image" 
            />
=      </div>
      <div className="p-anchor">
          <StaticImage 
            className="p-element hero-reflect spin-alt" 
            width={705} 
            placeholder={'BLURRED'}
            src="../images/main-hero-reflect.png" 
            alt="Background Image" 
          />
      </div>
      <StaticImage className="grid-desktop" src="../images/bg-grid.jpg" alt="Background Image" />
      <StaticImage className="grid-mobile" src="../images/bg-grid-mobile.png" alt="Background Image" />
    </div>
    <div className="mobile-cta d-flex justify-content-center ">
      <div className="cta-box cta-mobile  flex-column justify-content-between align-items-center">
        <p>
          A DROP BY <br/> 
          <a href="https://partyround.com" target="_blank" rel="noreferrer">PARTY ROUND</a>
          &nbsp;X&nbsp; 
          <a href="https://www.shrug.vc" target="_blank" rel="noreferrer">SHRUG</a>
        </p>
        <hr/>
        <h1>VC PUZZLE</h1>
        <h4>$0.99</h4>
        <hr/>
        <h6>69 VC FIRMS. ONE PUZZLE.</h6>
        <hr/>
        <div className="cta-actions">
          <a href="#" className="btn soldout">SOLD OUT</a>
        </div>
        <pre>¯\_(ツ)_/¯</pre>
      </div>
    </div>

  </>
)

export default IndexPage

/*
      <Sparkles style={{width:"50%", height: "100%", position: "fixed", top: 0, zIndex: 1}}>
      </Sparkles>
*/